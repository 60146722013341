import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';

import authReducer from './authReducer'
import adEntityReducer from './adEntityReducer'
import notificationReducer from './notificationReducer'

const store = createStore(
    combineReducers(
        {
            router: connectRouter(createBrowserHistory()), // 將 history 放到 state 名為 router
            auth: authReducer, // 指定 authReducer 放喺 state 嘅名為 auth
            adEntities: adEntityReducer,
            notifications: notificationReducer,
        }
    ),
    composeWithDevTools(applyMiddleware(thunkMiddleware)) // 容許 async
);
export default store;
//const unsubscribe = store.subscribe(() => { console.log(store.getState()) }); // 當有 change 嘅時候就行
