import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { authLogout } from '../redux/authReducer';

import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import ConfirmDialog from './common/ConfirmDialog';

import { Button } from '@material-ui/core';

import './NavMenu.css';

import NotificationIcon from '../components/svgIcons/NotificationIcon';
import UsersIcon from '../components/svgIcons/UsersIcon';
import LogoutIcon from '../components/svgIcons/LogoutIcon';

export default function NavMenu() {
    const { auth: { jWTClaimModel }, router: { location } } = useSelector(state => {
        return state;
    });


    const dispatch = useDispatch();

    const [ isCollapsed, setIsCollapsed ] = useState(true);
    const [ isWinLogoutOpen, setIsWinLogoutOpen ] = useState(false);

    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const collapseNavBar = () => {
        setIsCollapsed(true);
    };

    const history = useHistory();

    const redirectTo = async (path) => {
        switch (path) {
            case "/logout":
                dispatch(authLogout(history));
                break;
            default:
                history.push(path);
                break;
        }
    };

    return (
        <header>
            <ConfirmDialog
                title="登　出"
                open={isWinLogoutOpen}
                setOpen={setIsWinLogoutOpen}
                onConfirm={() => {
                    redirectTo("/logout");
                }}
            >
                你確定登出嗎？
            </ConfirmDialog>

            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light onSelect={collapseNavBar}>
                <Container>
                    <NavbarBrand tag={Link} to="/">Mobile ERP Admin</NavbarBrand>
                    {(jWTClaimModel && jWTClaimModel.adEntity && (jWTClaimModel.adEntity.id > 0)) && <React.Fragment>
                        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!isCollapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Button} className="text-dark" onClick={() => { redirectTo("/notifications"); }}>
                                        <NotificationIcon className="mr-1" />
                                        通知記錄
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Button} className="text-dark" onClick={() => { redirectTo("/users"); }}>
                                        <UsersIcon className="mr-1" />
                                        登記用戶
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Button} className="text-dark" onClick={() => { setIsWinLogoutOpen(true); }}>
                                        <LogoutIcon className="mr-1" />
                                        登出
                                    </NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </React.Fragment>}
                </Container>
            </Navbar>
        </header>
    );
}
