import React, { Component } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';

export default function Home() {
    return (
        <Breadcrumbs aria-label="breadcrumb" className="mb-3">
            <Typography color="textPrimary" variant="h6">首頁</Typography>
        </Breadcrumbs>
    );
}
