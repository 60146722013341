import React from 'react';
import _ from 'lodash';

import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

export default function OrderTableCell({ setOrder, field, sort, title }) {
    const _sort = JSON.parse(sort);
    const property = _.get(_sort, "[0].property");
    if (property != field) return (
        <TableCell><Button onClick={(e) => { e.currentTarget.blur(); setOrder(field); }}>{title}</Button></TableCell>
    );

    const direction = _.get(_sort, "[0].direction");
    if (direction == "ASC") return (
        <TableCell><Button onClick={(e) => { e.currentTarget.blur(); setOrder(field); }} endIcon={<ArrowDropUp />}>{title}</Button></TableCell>
    )
    else return (
        <TableCell><Button onClick={(e) => { e.currentTarget.blur(); setOrder(field); }} endIcon={<ArrowDropDown />}>{title}</Button></TableCell>
    );
}
