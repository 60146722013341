import axios from 'axios';
import _, { cloneDeep } from 'lodash';

// action types
const FetchNotificationActions = Object.freeze({
    RESET_NOTIFICATIONS: Symbol("RESET_NOTIFICATIONS"),

    UPDATE_FILTERS: Symbol("UPDATE_FILTERS"),
    SET_WORKING_ENTITY: Symbol("SET_WORKING_ENTITY"),

    FETCH_NOTIFICATIONS_REQUEST: Symbol("FETCH_NOTIFICATIONS_REQUEST"),
    FETCH_NOTIFICATIONS_SUCCESS: Symbol("FETCH_NOTIFICATIONS_SUCCESS"),
    FETCH_NOTIFICATIONS_FAILURE: Symbol("FETCH_NOTIFICATIONS_FAILURE"),

    FETCH_NOTIFICATION_REQUEST: Symbol("FETCH_NOTIFICATION_REQUEST"),
    FETCH_NOTIFICATION_SUCCESS: Symbol("FETCH_NOTIFICATION_SUCCESS"),
    FETCH_NOTIFICATION_FAILURE: Symbol("FETCH_NOTIFICATION_FAILURE"),

    CLEAR_FORM: Symbol("CLEAR_FORM"),
    FIELD_CHANGED: Symbol("FIELD_CHANGED"),

    FORM_SUCCESS: Symbol("FORM_SUCCESS"),
    FORM_FAILURE: Symbol("FORM_FAILURE"),

    FORM_PHOTO_SUCCESS: Symbol("FORM_PHOTO_SUCCESS"),
    FORM_PHOTO_FAILURE: Symbol("FORM_PHOTO_FAILURE"),
});

// action creator (returns action obj) 對應每一個 action，有可能食 param 砌 payload
// reducer 接咗 (見下面 reducer)，然後 switch 該個 action 嘅 type，再應用該個 action 有可能提供嘅 payload 改變 state
export const resetNotifications = () => {
    return {
        type: FetchNotificationActions.RESET_NOTIFICATIONS
    }
}

export const updateFilters = (filters) => {
    return {
        type: FetchNotificationActions.UPDATE_FILTERS,
        payload: filters,
    }
}

export const setWorkingEntity = (id) => {
    return {
        type: FetchNotificationActions.SET_WORKING_ENTITY,
        payload: id,
    }
}

// ajax list before
const _fetchNotificationsRequest = () => {
    return {
        type: FetchNotificationActions.FETCH_NOTIFICATIONS_REQUEST
    }
}

// ajax list success
const _fetchNotificationsSuccess = (result) => {
    return {
        type: FetchNotificationActions.FETCH_NOTIFICATIONS_SUCCESS,
        payload: result,
    }
}

// ajax list failure
const _fetchNotificationsFailure = (error) => {
    return {
        type: FetchNotificationActions.FETCH_NOTIFICATIONS_FAILURE,
        payload: error
    }
}

// async action creator (action creator returns action)
// thunkMiddleware allow action creator returns function (instead of an action obj)
export const fetchNotifications = (filter, workingEntityId) => {
    const apiEndpoint = `${window.location.origin}/api/Notification/GetEntities`;
    return (dispatch) => {
        dispatch(_fetchNotificationsRequest());
        axios.post(apiEndpoint, { ...filter,  workingEntityId: workingEntityId })
            .then(response => {
                dispatch(_fetchNotificationsSuccess(response.data));
            })
            .catch(error => {
                dispatch(_fetchNotificationsFailure (error.message));
            })
    }
}

// ajax entity before
const _fetchNotificationRequest = () => {
    return {
        type: FetchNotificationActions.FETCH_NOTIFICATION_REQUEST
    }
}


// ajax entity success
const _fetchNotificationSuccess = (entity) => {
    return {
        type: FetchNotificationActions.FETCH_NOTIFICATION_SUCCESS,
        payload: entity
    }
}

// ajax entity failure
const _fetchNotificationFailure = (error) => {
    return {
        type: FetchNotificationActions.FETCH_NOTIFICATIONS_FAILURE,
        payload: error
    }
}

// async action creator (action creator returns action)
// thunkMiddleware allow action creator returns function (instead of an action obj)
export const fetchNotification = (id) => {
    const apiEndpoint = `${window.location.origin}/api/Notification/GetEntities`;

    return (dispatch) => {
        dispatch(_fetchNotificationRequest());
        axios.post(apiEndpoint, {
            filter: JSON.stringify([{ "property": "Id", "value": id }])
        })
            .then(response => {
                if (!response.data.entities && response.data.entities.length != 1) throw new "未能讀取";

                dispatch(_fetchNotificationSuccess(response.data.entities[0]));
            })
            .catch(error => {
                dispatch(_fetchNotificationFailure(error.message));
            })
    }
}


export const clearForm = () => {
    return {
        type: FetchNotificationActions.CLEAR_FORM,
    }
}

export const fieldChanged = (field, value) => {
    return {
        type: FetchNotificationActions.FIELD_CHANGED,
        payload: {
            field: field,
            value: value,
        },
    };
}

// ajax submit success
const _formSuccess = ({ filter, entity }) => {
    return {
        type: FetchNotificationActions.FORM_SUCCESS,
        payload: { filter, entity },
    };
}

// ajax submit failure
const _formFailure = (error) => {
    return {
        type: FetchNotificationActions.FORM_FAILURE,
        payload: error
    }
}

// async action creator (action creator returns action)
// thunkMiddleware allow action creator returns function (instead of an action obj)
export const asyncSubmit = (entity, filter) => {
    const apiEndpoint = `${window.location.origin}/api/Notification/Save`;

    console.log(entity);

    return (dispatch) => {
        dispatch(_fetchNotificationRequest());
        axios.post(apiEndpoint, { entity , filter }, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data && response.data.success) {
                    dispatch(_formSuccess(response.data)); // token string
                } else {
                    dispatch(_formFailure([{ "__MODEL__": response.data && response.data.message ? response.data.message : "未能保存" }]));
                }
            })
            .catch(result => {
                if (result.response && result.response.data && result.response.data.errors) {
                    dispatch(_formFailure(result.response.data.errors));
                } else {
                    dispatch(_formFailure([{ "__MODEL__": `未能保存 ${result}` }]));
                }
            })
    }
}

// ajax photo submit success
const _formPhotoSuccess = ({ ImageUrl, ImageName }) => {
    return {
        type: FetchNotificationActions.FORM_PHOTO_SUCCESS,
        payload: { ImageUrl, ImageName },
    };
}

// ajax submit failure
const _formPhotoFailure = (error) => {
    return {
        type: FetchNotificationActions.FORM_PHOTO_FAILURE,
        payload: error
    }
}


export const asyncPhotoAdded = (fileItem) => {
    const apiEndpoint = `${window.location.origin}/api/Notification/SavePhoto`;

    return (dispatch) => {
        dispatch(_fetchNotificationRequest());

        let formData = new FormData();
        formData.append('Photo', fileItem.file);

        axios.post(apiEndpoint, formData, {
            timeout: 20000,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            if (response.data && response.data.Success) {
                dispatch(_formPhotoSuccess(response.data)); // token string
            } else {
                dispatch(_formPhotoFailure([{ "__MODEL__": response.data && response.data.message ? response.data.message : "未能上載" }]));
            }
        })
        .catch(result => {
            if (result.response && result.response.data) {
                dispatch(_formPhotoFailure(result.response.data));
            } else {
                dispatch(_formPhotoFailure([{ "__MODEL__": `未能上載 ${result}` }]));
            }
        })
    }
}

// whole state
const initialState = {
    isLoading: false,
    entities: [],
    error: '',
    filter: {
        row: 1,
        page: 1,
        qtyPerPage: 20,
        filter: "[]",
        sort: `[{"property":"DateEdit", "direction":"DESC"}]`,
    },
    totalRecords: 0,
    matchedRecords: 0,
    allRecords: 0,
    workingEntity: {
        Id: null,
        Topic: '[]',
        Tokens: '',
        MessageType: '其他',
        MessageInstance: '文字',
        Title: '',
        Body: '',
        Link: '',
        Html: '',
        RefNos: '',
        ImageUrl: '',
        ImageName: '',
        DateSchedule: new Date(),
    },
    workingErrors: {
        __MODEL__: [],
        Id: [],
        Topic: [],
        Tokens: [],
        MessageType: [],
        MessageInstance: [],
        Title: [],
        Body: [],
        Link: [],
        Html: [],
        RefNos: [],
        ImageUrl: [],
        DateSchedule: [],
    },
}

// reducer
const notificationReducer = (state = initialState, action) => {
    let _state = { ...state };

    switch (action.type) {
        case FetchNotificationActions.RESET_NOTIFICATIONS: // reset form
            return _state;

        case FetchNotificationActions.UPDATE_FILTERS:
            const filters = action.payload;
            _state.filter.filter = JSON.stringify(filters);
            _state.workingEntity = initialState.workingEntity;
            _state.page = 1;
            return _state;

        case FetchNotificationActions.SET_WORKING_ENTITY:
            const id = action.payload;
            const i = _.findIndex(state.entities, function (entity) { return entity.Id == id });
            if (i < 0) return state;

            _state.workingEntity = state.entities[i];
            return _state;

        case FetchNotificationActions.FETCH_NOTIFICATIONS_REQUEST: // before ajax list
            _state.isLoading = true; // show loading
            return _state;

        case FetchNotificationActions.FETCH_NOTIFICATIONS_SUCCESS: // ajax list success
            const result = action.payload;
            if (!result.success) {
                _state.isLoading = false;
                _state.entities = [];
                _state.error = result.message;
                return _state;
            }

            _state.isLoading = false;
            _state.entities = result.entities;
            _state.error = '';
            _state.filter = {
                filter: result.filter, 
                sort: result.sort,
                qtyPerPage: result.qtyPerPage,
                page: result.page,
                row: result.row,
            }
            _state.allRecords = result.allRecords;
            _state.matchedRecords = result.matchedRecords;
            _state.totalRecords = result.totalRecords;
            return _state;

        case FetchNotificationActions.FETCH_NOTIFICATIONS_FAILURE: // ajax list failure
            _state.isLoading = false;
            _state.entities = [];
            _state.error = action.payload;
            return _state;

        case FetchNotificationActions.FETCH_NOTIFICATION_REQUEST: // before ajax entity
            _state.isLoading = true; // show loading
            _state.workingEntity = cloneDeep(_state.workingEntity)
            _state.workingErrors = cloneDeep(initialState.workingErrors); // reset errors
            return _state;

        case FetchNotificationActions.FETCH_NOTIFICATION_SUCCESS: // ajax entity success
            _state.isLoading = false;
            _state.error = '';
            _state.workingEntity = action.payload;
            return _state;

        case FetchNotificationActions.FETCH_NOTIFICATION_FAILURE: // ajax entity failure
            _state.isLoading = false;
            _state.error = action.payload;
            return _state;

        case FetchNotificationActions.CLEAR_FORM: // clear form
            _state.workingEntity = cloneDeep(initialState.workingEntity);
            _state.workingErrors = cloneDeep(initialState.workingErrors);
            return _state;

        case FetchNotificationActions.FIELD_CHANGED: // fields changed
            const { field, value } = action.payload;
            _.set(_state.workingEntity, field, value);
            return _state;

        case FetchNotificationActions.FORM_SUCCESS: // form success
            _state.isLoading = false;
            _state.filter = action.payload.filter;
            _state.workingEntity = action.payload.entity;
            return _state;

        case FetchNotificationActions.FORM_FAILURE: // form failure
            _state.isLoading = false;
            _.forIn(action.payload, function (value, field) {
                _.set(_state.workingErrors, field.replace('Entity.', ''), value) // e.g. Entity.Body : 請輸入內容
            });
            return _state;

        case FetchNotificationActions.FORM_PHOTO_SUCCESS: // photo success
            const { ImageUrl, ImageName } = action.payload;
            _state.isLoading = false;
            _state.workingEntity.ImageUrl = ImageUrl;
            _state.workingEntity.ImageName = ImageName;
            return _state;

        case FetchNotificationActions.FORM_PHOTO_FAILURE: // photo failure
            _state.isLoading = false;
            _state.workingErrors.ImageUrl = action.payload 
            return _state;

        default:
            return state;
    }
}

export default notificationReducer;