import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotifications, clearForm } from '../redux/notificationReducer';
import cloneDeep from 'lodash/cloneDeep';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';

import NotificationSearch from './NotificationSearch';
import MyPagination from './common/MyPagination';
import OrderTableCell from './common/OrderTableCell';
import NotificationItem from './NotificationItem';

export default function Notification() {
    // 應用 history 取得上一頁 passed params 及跳至下一頁
    const history = useHistory();

    // 由 global state 取得 module 嘅 payload
    const { isLoading, entities, error, filter, allRecords, matchedRecords, totalRecords, workingEntity: { Id: workingEntityId } } = useSelector(state => {
        return state.notifications;
    });

    // 令本 funcComp 可以 dispatch action creator
    const dispatch = useDispatch();

    // funcComp 應用自己嘅 state
    const [isInit, setIsInit] = useState(true);
    const [isOpenSearch, setIsOpenSearch] = useState(false);

    const refEl = useRef(null);

    // funcComp 中，相似於 comp 嘅 componentDidMount 和 componentDidUpdate:
    useEffect(() => {
        if (!isInit) return;

        setIsInit(false);
        dispatch(fetchNotifications(filter, workingEntityId));
    });

    useEffect(() => {
        if (isLoading) return;
        if (!entities || (entities.length == 0)) return;
        if (!refEl.current) return;

        refEl.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [entities])

    //====================================

    function fetchPage(page) {
        let _filter = cloneDeep(filter);
        _filter.page = page;
        _filter.workingEntityId = null;
        history.push('/notifications', { params: _filter });
        dispatch(fetchNotifications(_filter, null));
    }

    function setOrder(field) {
        let sort = JSON.parse(filter.sort);
        if (sort.length == 0 || sort[0].property != field) {
            sort = [{ "property": field, "direction": "ASC" }];
        } else if (sort[0].property == field) {
            sort[0].direction = (sort[0].direction == "ASC" ? "DESC" : "ASC");
        }

        let _filter = cloneDeep(filter);
        _filter.sort = JSON.stringify(sort);
        dispatch(fetchNotifications(_filter, workingEntityId));
    }

    function createNotification() {
        dispatch(clearForm());
        history.push("/notification/new");
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                <Link color="inherit" onClick={() => { history.push("/"); }}>首頁</Link>
                <Typography color="textPrimary" variant="h6">通知記錄</Typography>
            </Breadcrumbs>
            <Button variant="contained" className="mb-3 mt-3 mr-2" onClick={createNotification} startIcon={<AddIcon/>}>建立通知</Button>
            <Button variant="contained" className="mb-3 mt-3 mr-2" onClick={(e) => { e.currentTarget.blur(); setIsOpenSearch(true); }} startIcon={<SearchIcon />}>搜尋</Button>
            <NotificationSearch open={isOpenSearch} setIsOpen={setIsOpenSearch} />
            <MyPagination allRecords={allRecords} totalRecords={totalRecords} qtyPerPage={filter.qtyPerPage} page={filter.page} qtyPage={Math.ceil(matchedRecords / filter.qtyPerPage)} setPage={fetchPage} className="mb-3 d-flex justify-content-end" />
            <TableContainer component={Paper} className="mb-4">
                <Table>
                    <TableHead>
                        <TableRow>
                            <OrderTableCell setOrder={setOrder} field="Title" sort={filter.sort} title="題目" />
                            <OrderTableCell setOrder={setOrder} field="Topic" sort={filter.sort} title="對象" />
                            <OrderTableCell setOrder={setOrder} field="DateSchedule" sort={filter.sort} title="發佈時間" />
                            <OrderTableCell setOrder={setOrder} field="DateCreate" sort={filter.sort} title="建立日期" />
                            <OrderTableCell setOrder={setOrder} field="CreateBy" sort={filter.sort} title="建立由" />
                            <OrderTableCell setOrder={setOrder} field="DateEdit" sort={filter.sort} title="修改日期" />
                            <OrderTableCell setOrder={setOrder} field="EditBy" sort={filter.sort} title="修改由" />
                            <TableCell>狀況</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading ?
                            <TableRow>
                                <TableCell colSpan="9" align="center"><div style={{ height: "600px", padding: "5em" }}>… 請稍候 …</div></TableCell>
                            </TableRow>
                            :
                            (!isLoading && error) ?
                                <TableRow>
                                    <TableCell colSpan="9" align="center"><div style={{ height: "600px", padding: "5em" }}>*** 未能讀取：{error} ***</div></TableCell>
                                </TableRow>
                                :
                                (!isLoading && !error && (entities.length == 0)) ?
                                    <TableRow>
                                        <TableCell colSpan="9" align="center">-- 沒有記錄 --</TableCell>
                                    </TableRow>
                                    :
                                    entities.map((notification) => {
                                        const _refEl = notification.Id == workingEntityId ? refEl : null;
                                        return (
                                            <NotificationItem notification={notification} key={notification.Id} refEl={_refEl} />
                                        );
                                    })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <MyPagination qtyPerPage={filter.qtyPerPage} page={filter.page} qtyPage={Math.ceil(matchedRecords / filter.qtyPerPage)} setPage={fetchPage} className="mb-4 d-flex justify-content-center" />
        </>
    );
}
