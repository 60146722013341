import React, { useRef } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { authMediaReady, authMediaError, authTogglePasswordMasked, authFormUpdate, asyncAuth } from '../redux/authReducer';

import _ from 'lodash';
import Webcam from "react-webcam";

import {
    Grid,
    FormControl,
    FormLabel,
    FormHelperText,
    Input,
    InputLabel,
    InputAdornment,
    Password,
    Button,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BeatLoader } from "react-spinners";

import { FiLogIn } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa'

const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: "user"
};


export default function Auth() {
    const history = useHistory();

    // 由 global state 取得 module 嘅 payload
    const { isPasswordMasked, isInitializing, isLoading, fields: { Login, Password, Base64Photo } } = useSelector(state => {
        return state.auth;
    });

    // 令本 funcComp 可以 dispatch action creator
    const dispatch = useDispatch();

    const videoRef = useRef(); // element reference
    const onSubmit = (e) => {
        e.preventDefault();

        const _b64photoValue = dispatch(authFormUpdate("base64photo", videoRef.current.getScreenshot())).payload.value;

        let formData = new FormData();
        formData.append('Login', Login.value);
        formData.append('Password', Password.value);
        formData.append('Base64Photo', _b64photoValue);

        dispatch(asyncAuth(formData, history)); // redirect if success
    };

    return (
        <>
            <h3 className="mt-4 mb-5">登　入</h3>

            <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item xs={12} sm={5} lg={3}>
                    <FormControl fullWidth error={!_.isEmpty(Base64Photo.errors)}>
                        <div style={{ margin: '0px auto', position: "relative", display: "flex", justifyContent: "center", width: "200px", height: "200px" }}>
                            <Webcam
                                ref={videoRef}
                                audio={false}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                onUserMedia={() => dispatch(authMediaReady())}
                                onUserMediaError={(error) => dispatch(authMediaError(error))}
                                width="100%"
                                height="100%"
                            />
                            <div hidden={!isInitializing} style={{ position: "absolute", alignSelf: "center" }}>
                                <BeatLoader className="loading" />
                            </div>
                        </div>
                        {!_.isEmpty(Base64Photo.errors) &&
                            <FormHelperText>{Base64Photo.errors.map((e, i) => <span key={i}>{e}</span>)}</FormHelperText>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={7} lg={5}>
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={!_.isEmpty(Login.errors)}>
                                    <InputLabel htmlFor="my-input">電　　郵</InputLabel>
                                    <Input id="Login" aria-describedby="my-helper-text" autoFocus value={Login.value} onChange={(e) => { dispatch(authFormUpdate("login", e.target.value)); }} />
                                    {!_.isEmpty(Login.errors) &&
                                        <FormHelperText>{Login.errors.map((e, i) => <span key={i}>{e}</span>)}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={!_.isEmpty(Password.errors)}>
                                    <InputLabel htmlFor="my-input">密　　碼</InputLabel>
                                    <Input id="Password" type={isPasswordMasked ? 'password' : 'text'} aria-describedby="my-helper-text" value={Password.value} endAdornment={
                                        <InputAdornment position="end" onClick={() => { dispatch(authTogglePasswordMasked()); }}>
                                            <FaEye />
                                        </InputAdornment>
                                    } onChange={(e) => { dispatch(authFormUpdate("password", e.target.value)); }} />
                                    {!_.isEmpty(Password.errors) &&
                                        <FormHelperText>{Password.errors.map((e, i) => <span key={i}>{e}</span>)}</FormHelperText>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container alignContent="flex-end">
                                    <Button type="submit" color="primary" variant="contained" disableElevation>
                                        <FiLogIn className="mr-2" />
                                        <span>登 入</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>

            <Dialog open={isLoading}>
                <DialogContent>
                    <Box display="flex" >
                        <Box m="auto">
                            <CircularProgress />
                        </Box>
                    </Box>
                    <DialogContentText m="auto">請稍候…</DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
