import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { filterUpdate, fetchAdEntities } from '../redux/adEntityReducer';
import cloneDeep from 'lodash/cloneDeep';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { getFilterValue } from '../helpers/filterHelpers'
import LookupField from './common/LookupField';
import MyPagination from './common/MyPagination';
import AdEntryItem from './AdEntryItem';

export default function AdEntry() {
    // 應用 history 取得上一頁 passed params 及跳至下一頁
    const history = useHistory();
    const params = useParams(); // router params
    //console.log(params);

    // 由 global state 取得 module 嘅 payload
    const { loading, entities, error, filter, allRecords, matchedRecords, totalRecords } = useSelector(state => {
        return state.adEntities;
    });

    // 令本 funcComp 可以 dispatch action creator
    const dispatch = useDispatch();

    // funcComp 應用自己嘅 state
    const [isInit, setIsInit] = useState(false);
    const [isLookup, setIsLookup] = useState(false);

    // funcComp 中，相似於 comp 嘅 componentDidMount 和 componentDidUpdate:
    useEffect(() => {
        if (isInit) return;

        setIsInit(true);
        dispatch(fetchAdEntities(filter));
    });

    //====================================

    function localFetchAdEntities() {
        dispatch(fetchAdEntities(filter));
    }

    function fetchPage(page) {

        let _filter = cloneDeep(filter);
        _filter.page = page;
        history.push('/users', { params: _filter });
        //console.log('params', params);
        dispatch(fetchAdEntities(_filter));
    }

    function updatePageSize(qtyPerPage) {
        let _filter = cloneDeep(filter);
        _filter.page = 1; // reset page
        _filter.qtyPerPage = qtyPerPage;
        dispatch(fetchAdEntities(_filter));
    }

    function setOrder(field) {
        let sort = JSON.parse(filter.sort);
        if (sort.length == 0 || sort[0].property != field) {
            sort = [{ "property": field, "direction": "ASC" }];
        } else if (sort[0].property == field) {
            sort[0].direction = (sort[0].direction == "ASC" ? "DESC" : "ASC");
        }

        let _filter = cloneDeep(filter);
        _filter.page = 1; // reset page
        _filter.sort = JSON.stringify(sort);
        dispatch(fetchAdEntities(_filter));
    }

    function updateFilter(property, value) {
        dispatch(filterUpdate(property, value));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(fetchAdEntities(filter));
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                <Link color="inherit" onClick={() => { history.push("/"); }}>首頁</Link>
                <Typography color="textPrimary" variant="h6">登記用戶</Typography>
            </Breadcrumbs>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <LookupField value={getFilterValue(filter.filter, "s_Login")} propertyName="s_Login" fnUpdateFilter={updateFilter} fnLookup={localFetchAdEntities} />
            </form>
            <MyPagination allRecords={allRecords} totalRecords={totalRecords} qtyPerPage={filter.qtyPerPage} page={filter.page} qtyPage={Math.ceil(matchedRecords / filter.qtyPerPage)} setPage={fetchPage} className="mb-3 d-flex justify-content-end" />
            <TableContainer className="mb-4" component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Button onClick={() => { setOrder("DisplayName"); }}>名字</Button></TableCell>
                            <TableCell><Button onClick={() => { setOrder("Login"); }}>帳號</Button></TableCell>
                            <TableCell><Button onClick={() => { setOrder("LastName"); }}>LastName</Button></TableCell>
                            <TableCell align="center"><Button onClick={() => { setOrder("FaceEncoding"); }}>已登記</Button></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ?
                            <TableRow>
                                <TableCell colSpan="4" align="center"><div style={{ height: "600px", padding: "5em" }}>… 請稍候 …</div></TableCell>
                            </TableRow>
                            :
                            (!loading && error) ?
                                <TableRow>
                                    <TableCell colSpan="4" align="center"><div style={{ height: "600px", padding: "5em" }}>*** 未能讀取：{error} ***</div></TableCell>
                                </TableRow>
                                :
                                (!loading && !error && (entities.length == 0)) ?
                                    <TableRow>
                                        <TableCell colSpan="4" align="center">-- 沒有記錄 --</TableCell>
                                    </TableRow>
                                    :
                                    entities.map((adEntry) => <AdEntryItem adEntry={adEntry} key={adEntry.Id} />)
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <MyPagination qtyPerPage={filter.qtyPerPage} page={filter.page} qtyPage={Math.ceil(matchedRecords / filter.qtyPerPage)} setPage={fetchPage} className="mb-4 d-flex justify-content-center" />
        </>
    );
}
