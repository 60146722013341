import React, { useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { useDispatch } from 'react-redux';
import { fieldChanged } from '../redux/notificationReducer';


import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function NotificationTopicAutocomplete({ value, error }) {
    const dispatch = useDispatch();
    const [isInit, setIsInit] = React.useState(true);
    const [options, setOptions] = React.useState([]);
    const [_value, setValue] = React.useState("");
    const [inputValue, setInputValue] = React.useState("");

    const [open, setOpen] = React.useState(false);
    const loading = open && options.length < 1; // 1st is allUser

    React.useEffect(() => {
        if (!isInit) return undefined;
        setIsInit(false);

        setValue(JSON.parse(value) ? JSON.parse(value) : []);

        (async () => {
            const response = await axios.get(`${window.location.origin}/api/AdEntity/GetNotificationTopics`, {
                timeout: 10000,
            });
            setOptions(["alluser", ...response.data]);
        })();
    });

    return (
        <Autocomplete
            multiple
            fullWidth
            open={open}
            onOpen={() => { setOpen(true); }}
            onClose={() => { setOpen(false); }}
            value={_value}
            onChange={(e, v) => { setValue(v); dispatch(fieldChanged("Topic", JSON.stringify(v))); }}
            inputValue={inputValue}
            onInputChange={(e, v) => { setInputValue(v); }}
            options={options}
            getOptionSelected={(option, value) => option === value}
            renderInput={function (params) {
                return (
                    <TextField
                    {...params}
                        label="對象"
                        error={!_.isEmpty(error)}
                        helperText={_.isEmpty(error) ? "" : error}
                        onKeyDown={(e) => { switch (e.keyCode) { case 13: e.preventDefault(); break; } }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                    />
               );
            }}
        />
    );
}
