import React, { useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

const useStyles = makeStyles({
    root: {
        transition: "width 0.25s ease",
        width: "210px",
        "&.focused": {
            width: "310px",
        },
        "& > .btnClear": {
            transition: "opacity 0.5s ease",
            visibility: "hidden",
            opacity: "0",
        },
        "& > .btnClear.isVisible": {
            display: "flex",
            visibility: "visible",
            opacity: "1",
        }
    },
});

export default function LookupField({ value, propertyName, fnUpdateFilter, fnLookup }) {
    const [isFocused, setIsFocused] = useState(false);

    const classes = useStyles();

    return (
        <ClickAwayListener onClickAway={() => { setIsFocused(false); }}>
            <OutlinedInput
                className={clsx({ "mb-3": true, [classes.root]: true, "focused": isFocused })}
                margin="dense"
                placeholder="搜　尋"
                value={value}
                onChange={(e) => { return fnUpdateFilter(propertyName, e.target.value); }}
                onFocus={() => { setIsFocused(true);}}
                endAdornment={
                    <>
                        <InputAdornment className={clsx({ "btnClear": true, "isVisible": (value ? true : false) })}>
                            <IconButton onClick={() => { fnUpdateFilter(propertyName, ""); }}><Clear /></IconButton>
                        </InputAdornment>
                        <InputAdornment>
                            <IconButton onClick={(e) => { e.currentTarget.blur(); fnLookup(); }}><Search /></IconButton>
                        </InputAdornment>
                    </>
                }
            />
        </ClickAwayListener>
    );
}
