import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const UsersIcon = (props) => {
    return <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.5 92.5">
            <path fill="none" d="M-1-1h582v402H-1z" />
            <g>
                <circle cx="46.25" cy="46.25" r="46.25" fill="#00AEEF" />
                <circle cx="66.442" cy="38.323" r="8.266" fill="#FFF" />
                <path fill="#FFF" d="M68.736 48.713l-1.704 3.139-1.932-3.141c-6.13-.045-10.584 0-10.584 6.387 0 7.043 5.877 10.457 12.984 10.457 7.108 0 12.756-3.414 12.756-10.457.001-6.471-5.167-6.434-11.52-6.385z" />
                <circle cx="23.109" cy="38.323" r="8.266" fill="#FFF" />
                <path fill="#FFF" d="M25.403 48.713l-1.704 3.139-1.932-3.141c-6.13-.045-10.584 0-10.584 6.387 0 7.043 5.877 10.457 12.984 10.457 7.108 0 12.756-3.414 12.756-10.457 0-6.471-5.168-6.434-11.52-6.385z" />
                <circle cx="44.125" cy="32.197" r="12.031" fill="#FFF" stroke="#00AEEF" />
                <g>
                    <path fill="#FFF" d="M47.464 47.32l-2.479 4.567-2.813-4.57c-8.926-.068-15.405-.004-15.405 9.296 0 10.252 8.554 15.221 18.9 15.221 10.347 0 18.566-4.969 18.566-15.221 0-9.421-7.521-9.364-16.769-9.293z" />
                    <path fill="none" stroke="#00AEEF" d="M47.464 47.32l-2.479 4.567-2.813-4.57c-8.926-.068-15.405-.004-15.405 9.296 0 10.252 8.554 15.221 18.9 15.221 10.347 0 18.566-4.969 18.566-15.221 0-9.421-7.521-9.364-16.769-9.293z" />
                </g>
            </g>
        </svg>
    </SvgIcon>
}

export default UsersIcon;
