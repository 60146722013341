import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import { CssBaseline } from '@material-ui/core';

import store from './redux/store';

import Layout from './components/Layout';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Auth from './components/Auth';
import AdEntry from './components/AdEntry';
import Notification from './components/Notification';
import NotificationForm from './components/NotificationForm';
import SavePhoto from './components/SavePhoto';

import './custom.css'

export default function App() {
    const history = useHistory();

    return (
        <Provider store={store}>
            <ConnectedRouter history={history}> {/* 將 route 加到 state，令非 <Route> 都可以 connect state.route 而隨 history 改變而 update，比如 <NavMenu> */}
                <Layout>
                    <CssBaseline />
                    <Switch>
                        {/* path 一中就 return <Route> 指定嘅 component */}
                        <Route path='/auth' component={Auth} />
                        <Route path='/users/:page' component={AdEntry} />
                        <Route path='/users' component={AdEntry} />
                        <Route path='/save_photo/:login' component={SavePhoto} />
                        <Route path='/save_photo' component={SavePhoto} />
                        <Route path='/notification/:id' component={NotificationForm} />
                        <Route path='/notifications' component={Notification} />
                        <Route path='/not-found' component={NotFound} />
                        <Route exact path='/' component={Home} />
                        <Redirect to='/not-found' />
                    </Switch>
                </Layout>
            </ConnectedRouter>
        </Provider>
    );
}
