import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Moment from 'moment';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import StopwatchIcon from './svgIcons/StopwatchIcon';
import CircleMailIcon from './svgIcons/CircleMailIcon';
import TickIcon from './svgIcons/TickIcon';

import { setWorkingEntity } from '../redux/notificationReducer';

const useStyles = makeStyles({
    root: {
        "transition": "background-color 0.25s ease",
        "cursor": "pointer",
        "&.Mui-selected": {
            background: "rgb(251 254 106 / 8%)",
        },
    },
});

export default function NotificationItem({ notification, refEl }) {
    const history = useHistory();

    //console.log(notification);
    const { Id, Title, Topic, DateSchedule, DateCreate, CreateBy, DateEdit, EditBy, Status } = notification;

    Moment.locale('hk');

    // 令本 funcComp 可以 dispatch action creator
    const dispatch = useDispatch();

    function editNotification(id) {
        history.push(`/notification/${id}`);
    }

    const classes = useStyles();

    return (
        <TableRow className={classes.root} ref={refEl} selected={refEl != null ? true : false} hover onClick={() => { dispatch(setWorkingEntity(Id)); }}>
            <TableCell>{Title}</TableCell>
            <TableCell>{Topic}</TableCell>
            <TableCell>{Moment(DateSchedule).format('yyyy-MM-DD HH:mm')}</TableCell>
            <TableCell>{Moment(DateCreate).format('yyyy-MM-DD HH:mm')}</TableCell>
            <TableCell>{CreateBy}</TableCell>
            <TableCell>{Moment(DateEdit).format('yyyy-MM-DD HH:mm')}</TableCell>
            <TableCell>{EditBy}</TableCell>
            <TableCell>
                {(Status == "IDLE") && <StopwatchIcon />}
                {(Status == "SENT") && <CircleMailIcon />}
                {(Status == "READ") && <TickIcon />}
            </TableCell>
            <TableCell align="center"><Button variant="contained" color="default" onClick={() => { editNotification(Id); }}>詳情</Button></TableCell>
        </TableRow>
    );
}