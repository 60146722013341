import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const SlashIcon = (props) => {
    return <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 263">
            <path fill="none" d="M-1-1h267v265H-1z" />
            <g>
                <circle cx="132.5" cy="130" r="110" fill="#fff" />
                <path fill="#ffb300" d="M132 1.792C60.09 1.792 1.792 60.09 1.792 132.004c0 71.901 58.298 130.204 130.208 130.204s130.208-58.303 130.208-130.204C262.208 60.09 203.91 1.792 132 1.792zm95.486 130.212c0 20.091-6.22 38.724-16.823 54.102L77.89 53.336C93.272 42.735 111.904 36.515 132 36.515c52.734 0 95.486 42.756 95.486 95.49zm-190.972 0c0-20.095 6.224-38.732 16.823-54.114L186.11 210.663c-15.377 10.603-34.015 16.827-54.11 16.827-52.734 0-95.486-42.76-95.486-95.486z" />
            </g>
        </svg>
    </SvgIcon>
}

export default SlashIcon;