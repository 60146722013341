import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { authRequestByStorage } from '../redux/authReducer';

import { BeatLoader } from "react-spinners";
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import CenterElement from './common/CenterElement';

export default function Layout({ children }) {
    const history = useHistory();

    const [isInited, setIsInited] = useState(false);

    // 由 global state 取得 module 嘅 payload
    const { isBeforeLoadingByStorage } = useSelector(state => {
        return state.auth;
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isInited) {
            dispatch(authRequestByStorage(history));
        }

        setIsInited(true);
    });

    return (
        isBeforeLoadingByStorage ?
            <CenterElement>
                <BeatLoader />
            </CenterElement> 
            :
            <>
                <NavMenu />
                <Container>
                    {children}
                </Container>
            </>
    );
}
