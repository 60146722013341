import React, { Component } from 'react';

import { Pagination } from 'react-bootstrap';
import Grid from '@material-ui/core/Grid';

export default function MyPagination(props) {
    const { page, qtyPerPage, qtyPage, setPage, className, allRecords, totalRecords } = props;
    if (qtyPage <= 1) return '';

    //return <span className="d-none d-sm-inline-block" >xxxx</span>

    return (
        allRecords
        ?
            <Grid container alignItems="center">
                <Grid item xs={6}>
                    <span>第 {(page - 1) * qtyPerPage + 1} - {(page - 1) * qtyPerPage + totalRecords } 筆，總 {allRecords} 筆</span>
                </Grid>
                <Grid item xs={6}>
                    <Pagination className={className != null ? className : "mb-3 d-flex justify-content-center"}>
                        <Pagination.First disabled={page <= 1} onClick={(e) => { e.currentTarget.blur(); setPage(1); }} />
                        <Pagination.Prev disabled={page <= 1} onClick={(e) => { e.currentTarget.blur(); setPage(page - 1); }} />

                        {(page > 4 && page + 1 > qtyPage) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page - 4); }}>{page - 4}</Pagination.Item>}
                        {(page > 3 && page + 2 > qtyPage) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page - 3); }}>{page - 3}</Pagination.Item>}
                        {(page - 2 >= 1) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page - 2); }}>{page - 2}</Pagination.Item>}
                        {(page - 1 >= 1) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page - 1); }}>{page - 1}</Pagination.Item>}
                        <Pagination.Item disabled={true}>{page}</Pagination.Item>
                        {(page + 1 <= qtyPage) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page + 1); }}>{page + 1}</Pagination.Item>}
                        {(page + 2 <= qtyPage) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page + 2); }}>{page + 2}</Pagination.Item>}
                        {(qtyPage > 3 && page - 2 < 1) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page + 3); }}>{page + 3}</Pagination.Item>}
                        {(qtyPage > 4 && page - 1 < 1) && <Pagination.Item className="d-none d-sm-inline-block" onClick={(e) => { e.currentTarget.blur(); setPage(page + 4); }}>{page + 4}</Pagination.Item>}

                        <Pagination.Next disabled={page >= qtyPage} onClick={(e) => { e.currentTarget.blur(); setPage(page + 1); }} />
                        <Pagination.Last disabled={page >= qtyPage} onClick={(e) => { e.currentTarget.blur(); setPage(qtyPage); }} />
                    </Pagination>
                </Grid>
            </Grid>
        :
            <Pagination className={className != null ? className : "mb-3 d-flex justify-content-center"}>
                <Pagination.First disabled={page <= 1} onClick={(e) => { e.currentTarget.blur(); setPage(1); }} />
                <Pagination.Prev disabled={page <= 1} onClick={(e) => { e.currentTarget.blur(); setPage(page - 1); }} />

                {(page > 4 && page + 1 > qtyPage) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page - 4); }}>{page - 4}</Pagination.Item>}
                {(page > 3 && page + 2 > qtyPage) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page - 3); }}>{page - 3}</Pagination.Item>}
                {(page - 2 >= 1) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page - 2); }}>{page - 2}</Pagination.Item>}
                {(page - 1 >= 1) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page - 1); }}>{page - 1}</Pagination.Item>}
                <Pagination.Item disabled={true}>{page}</Pagination.Item>
                {(page + 1 <= qtyPage) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page + 1); }}>{page + 1}</Pagination.Item>}
                {(page + 2 <= qtyPage) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page + 2); }}>{page + 2}</Pagination.Item>}
                {(qtyPage > 3 && page - 2 < 1) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page + 3); }}>{page + 3}</Pagination.Item>}
                {(qtyPage > 4 && page - 1 < 1) && <Pagination.Item onClick={(e) => { e.currentTarget.blur(); setPage(page + 4); }}>{page + 4}</Pagination.Item>}

                <Pagination.Next disabled={page >= qtyPage} onClick={(e) => { e.currentTarget.blur(); setPage(page + 1); }} />
                <Pagination.Last disabled={page >= qtyPage} onClick={(e) => { e.currentTarget.blur(); setPage(qtyPage); }} />
            </Pagination>
    );
}


