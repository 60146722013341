import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TickIcon = (props) => {
    return <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215.434 215.434">
            <circle cx="107.717" cy="107.717" r="106.299" fill="#54C147" />
            <path fill="#FFF" d="M96.977 121.718l48.107-70.928 23.668 18.23-65.169 95.627-56.905-44.348 16.884-23.897z" />
        </svg>
    </SvgIcon>
}

export default TickIcon;