import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotifications, updateFilters } from '../redux/notificationReducer';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import _ from 'lodash';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import BlockIcon from '@material-ui/icons/Block';
import SearchIcon from '@material-ui/icons/Search';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import zhTW from 'date-fns/locale/zh-TW';
import moment from 'moment';

const useStyles = makeStyles({
    hasPadding: {
        padding: '1.25em',
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationSearch({ open, setIsOpen }) {
    // 令本 funcComp 可以 dispatch action creator
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // 由 global state 取得 module 嘅 payload，一直 listen 住 "filter"
    const { filter } = useSelector(state => {
        return state.notifications;
    });

    const { filter: filters } = filter;

    const [isInit, setIsInit] = useState(true);
    const [l_Title, setSTitle] = useState("");
    const [s_CreateBy, setSCreateBy] = useState("");
    const [f_DateCreate, setFDateCreate] = useState(null);
    const [t_DateCreate, setTDateCreate] = useState(null);
    const [f_DateSchedule, setFDateSchedule] = useState(null);
    const [t_DateSchedule, setTDateSchedule] = useState(null);

    useEffect(() => {
        if (!open) return;

        const _filters = filters ? JSON.parse(filters) : [];

        clearForm();

        var i = -1;

        i = _.findIndex(_filters, function (f) { return _.get(f, "property") == "l_Title"; });
        if (i >= 0) setSTitle(_.get(_filters[i], "value"));

        i = _.findIndex(_filters, function (f) { return _.get(f, "property") == "s_CreateBy"; });
        if (i >= 0) setSCreateBy(_.get(_filters[i], "value"));

        i = _.findIndex(_filters, function (f) { return _.get(f, "property") == "f_DateCreate"; });
        if (i >= 0) setFDateCreate(_.get(_filters[i], "value"));

        i = _.findIndex(_filters, function (f) { return _.get(f, "property") == "t_DateCreate"; });
        if (i >= 0) setTDateCreate(_.get(_filters[i], "value"));

        i = _.findIndex(_filters, function (f) { return _.get(f, "property") == "f_DateSchedule"; });
        if (i >= 0) setFDateSchedule(_.get(_filters[i], "value"));

        i = _.findIndex(_filters, function (f) { return _.get(f, "property") == "t_DateSchedule"; });
        if (i >= 0) setTDateSchedule(_.get(_filters[i], "value"));

    }, [ open ]);

    function performLookup() {
        var _filters = [];
        if (l_Title) _filters.push({ "property": "l_Title", "value": l_Title });
        if (s_CreateBy) _filters.push({ "property": "s_CreateBy", "value": s_CreateBy });
        if (f_DateCreate) _filters.push({ "property": "f_DateCreate", "value": moment(f_DateCreate).format("YYYY-MM-DD HH:mm:ss") });
        if (t_DateCreate) _filters.push({ "property": "t_DateCreate", "value": moment(t_DateCreate).format("YYYY-MM-DD HH:mm:ss") });
        if (f_DateSchedule) _filters.push({ "property": "f_DateSchedule", "value": moment(f_DateSchedule).format("YYYY-MM-DD HH:mm:ss") });
        if (t_DateSchedule) _filters.push({ "property": "t_DateSchedule", "value": moment(t_DateSchedule).format("YYYY-MM-DD HH:mm:ss") });

        setIsOpen(false);
        dispatch(updateFilters(_filters));
    }

    // 當 filters 有 change 嘅話 (performLookup() 之後)，而唔係第一次 init，就拎新嘅 filter，dispatch fetchNotifications
    useEffect(() => {
        if (!isInit) {
            filter.page = 1; 
            dispatch(fetchNotifications(filter));
        }
        setIsInit(false);
    }, [filters]);

    function clearForm() {
        setSTitle("");
        setSCreateBy("");
        setFDateCreate(null);
        setTDateCreate(null);
        setFDateSchedule(null);
        setTDateSchedule(null);
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            fullScreen={fullScreen} 
            keepMounted
            onClose={() => { setIsOpen(false); }}
        >
            <DialogTitle>搜尋</DialogTitle>
            <DialogContent>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTW} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label="題目" value={l_Title} onChange={(e) => { setSTitle(e.target.value); }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField fullWidth label="建立由" value={s_CreateBy} onChange={(e) => { setSCreateBy(e.target.value); }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <KeyboardDatePicker
                                fullWidth
                                format="yyyy-MM-dd"
                                label="建立日期由"
                                value={f_DateCreate}
                                autoOk
                                onChange={(v) => { setFDateCreate(v ? new Date(v.getFullYear(), v.getMonth(), v.getDate(), 0, 0, 0): null); }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <KeyboardDatePicker
                                fullWidth
                                format="yyyy-MM-dd"
                                label="建立日期至"
                                value={t_DateCreate}
                                autoOk
                                onChange={(v) => { setTDateCreate(v ? new Date(v.getFullYear(), v.getMonth(), v.getDate(), 23, 59, 59) : null); }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <KeyboardDatePicker
                                fullWidth
                                format="yyyy-MM-dd"
                                label="發佈日期由"
                                value={f_DateSchedule}
                                autoOk
                                onChange={(v) => { setFDateSchedule(v ? new Date(v.getFullYear(), v.getMonth(), v.getDate(), 0, 0, 0) : null); }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <KeyboardDatePicker
                                fullWidth
                                format="yyyy-MM-dd"
                                label="發佈日期至"
                                value={t_DateSchedule}
                                autoOk
                                onChange={(v) => { setTDateSchedule(v ? new Date(v.getFullYear(), v.getMonth(), v.getDate(), 23, 59, 59): null); }}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions className={classes.hasPadding}>
                <Button onClick={(e) => { e.currentTarget.blur(); performLookup(); }} color="primary" variant="contained" startIcon={ <SearchIcon/> }>搜尋</Button>
                <Button onClick={(e) => { e.currentTarget.blur(); clearForm(); }} color="primary" variant="contained" startIcon={ <ClearAllIcon/> }>清除</Button>
                <Button onClick={(e) => { e.currentTarget.blur(); setIsOpen(false); }} color="primary" variant="contained" startIcon={<BlockIcon/> }>取消</Button>
            </DialogActions>
        </Dialog>
    );
}