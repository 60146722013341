import axios from 'axios';
import _, { cloneDeep } from 'lodash';
import jwt_decode from 'jwt-decode';

//================== Action Types ==================//
export const AuthActions = Object.freeze({
    // logging page actions
    AUTH_MEDIA_READY: Symbol("AUTH_MEDIA_READY"),
    AUTH_MEDIA_ERROR: Symbol("AUTH_MEDIA_ERROR"),
    AUTH_FORM_UPDATE: Symbol("AUTH_FORM_UPDATE"),
    AUTH_TOGGLE_PASSWORD_MASKED: Symbol("AUTH_TOGGLE_PASSWORD_MASKED"),

    // logging ajax actions
    AUTH_REQUEST: Symbol("AUTH_REQUEST"),
    AUTH_SUCCESS: Symbol("AUTH_SUCCESS"),
    AUTH_FAILURE: Symbol("AUTH_FAILURE"),
    AUTH_LOGOUT: Symbol("AUTH_LOGOUT"),

    // logging by storage actions
    AUTH_REQUEST_BY_STORAGE: Symbol("AUTH_REQUEST_BY_STORAGE"),
    AUTH_SUCCESS_BY_STORAGE: Symbol("AUTH_SUCCESS_BY_STORAGE"),
    AUTH_FAILURE_BY_STORAGE: Symbol("AUTH_FAILURE_BY_STORAGE"),
});

//================== Action Creators ==================//
export const authMediaReady = () => {
    return {
        type: AuthActions.AUTH_MEDIA_READY,
    }
}

export const authMediaError = (error) => {
    return {
        type: AuthActions.AUTH_MEDIA_ERROR,
        payload: error,
    }
}

export const authFormUpdate = (field, value) => {
    return {
        type: AuthActions.AUTH_FORM_UPDATE,
        payload: {
            field: field,
            value: value,
        }
    }
}

export const authTogglePasswordMasked = () => {
    return {
        type: AuthActions.AUTH_TOGGLE_PASSWORD_MASKED,
    }
}

const _authRequest = () => {
    return {
        type: AuthActions.AUTH_REQUEST
    }
}

const _authSuccess = (token) => {
    return {
        type: AuthActions.AUTH_SUCCESS,
        payload: token,
    }
}

const _authFailure = (errors) => {
    return {
        type: AuthActions.AUTH_FAILURE,
        payload: errors,
    }
}

const _authRequestByStorage = () => {
    return {
        type: AuthActions.AUTH_REQUEST_BY_STORAGE
    }
}

const _authSuccessByStorage = (token, jWTClaimModel) => {
    return {
        type: AuthActions.AUTH_SUCCESS_BY_STORAGE,
        payload: {
            token: token,
            jWTClaimModel: jWTClaimModel,
        }
    }
}

const _authFailureByStorage = (history) => {
    return {
        type: AuthActions.AUTH_FAILURE_BY_STORAGE,
        payload: history,
    }
}

export const authLogout = (history) => {
    return {
        type: AuthActions.AUTH_LOGOUT,
        payload: history,
    }
}

export const asyncAuth = (formData, history) => {
    const apiEndpoint = `${window.location.origin}/api/Authentication/AdLogin`;

    return (dispatch) => {
        dispatch(_authRequest());
        axios.post(apiEndpoint, formData, {
            timeout: 30000,
        })
            .then(response => {
                if (response.data) {
                    dispatch(_authSuccess(response.data)); // token string
                    history.push("/");
                } else {
                    dispatch(_authFailure([{ "Base64Photo": "未能取得 token" }]));
                }
            })
            .catch(result => {
                if (result.response && result.response.data && result.response.data.errors) {
                    dispatch(_authFailure(result.response.data.errors));
                } else {
                    dispatch(_authFailure([{ "Base64Photo": `未能登入 ${result}` }]));
                }
            })
    }
}

export const authRequestByStorage = (history) => {
    const apiEndpoint = `${window.location.origin}/api/Authentication/GetSession`;
    return (dispatch) => {
        const token = localStorage.getItem("token");
        if (_.isEmpty(token)) {
            dispatch(_authFailureByStorage(history));
            return;
        }

        dispatch(_authRequestByStorage());
        axios.post(apiEndpoint, null, {
            timeout: 30000,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        })
            .then(response => {
                if (response.data) {
                    dispatch(_authSuccessByStorage(token, response.data));
                } else {
                    dispatch(_authFailureByStorage(history));
                }
            })
            .catch(result => {
                dispatch(_authFailureByStorage(history));
            });
    }
}


//================== Whole State ==================//
const initialState = {
    isPasswordMasked: true,
    isInitializing: true,
    isLoading: false,
    isMediaReady: false,
    isBeforeLoadingByStorage: true,
    fields: {
        Login: {
            value: '',
            errors: [],
        },
        Password: {
            value: '',
            errors: [],
        },
        Base64Photo: {
            value: '',
            errors: [],
        }
    },
    token: '',
    jWTClaimModel: null,
};

//================== Reducer ==================//
const authReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    let history = null;

    switch (action.type) {
        case AuthActions.AUTH_MEDIA_READY:
            _state.isInitializing = false;
            _state.isMediaReady = true;
            _state.mediaError = '';
            return _state;

        case AuthActions.AUTH_MEDIA_ERROR:
            _state.isInitializing = false;
            _state.isMediaReady = false;
            _state.Base64Photo.errors = ["未能取得相機"];
            return _state;

        case AuthActions.AUTH_TOGGLE_PASSWORD_MASKED:
            _state.isPasswordMasked = !_state.isPasswordMasked;
            return _state;

        case AuthActions.AUTH_FORM_UPDATE:
            const { field, value } = action.payload;
            switch (field) {
                case "login":
                    const match = value.match(/(.+)@c.*/);
                    if (match) {
                        _state.fields.Login.value = `${match[1]}@century21-goodwin.com`;
                        break;
                    }

                    _state.fields.Login.value = value;
                    break;
                case "password": _state.fields.Password.value = value; break;
                case "base64photo": _state.fields.Base64Photo.value = value; break;
            }
            return _state;

        case AuthActions.AUTH_REQUEST:
            _state.isLoading = true;

            // clear errors
            _.forIn(_state.fields, function (value, key) {
                _state.fields[key].errors = [];
            });
            return _state;

        case AuthActions.AUTH_SUCCESS:
            _state.isLoading = false;
            _state.token = action.payload;
            _state.jWTClaimModel = JSON.parse(jwt_decode(_state.token).JWTClaimModel);

            axios.defaults.headers.common['Authorization'] = `Bearer ${_state.token}`;

            localStorage.setItem('token', _state.token);
            localStorage.setItem('JWTClaimModel', jwt_decode(_state.token).JWTClaimModel);

            return _state;

        case AuthActions.AUTH_FAILURE:
            axios.defaults.headers.common['Authorization'] = null;

            localStorage.setItem('token', null);
            localStorage.setItem('JWTClaimModel', null);

            _state.isLoading = false;
            _state.token = '';
            _state.jWTClaimModel = null;

            const errors = action.payload;
            // assign errors
            _.forIn(errors, function (value, key) {
                if (!_.isObject(_state.fields[key])) return; // skips fields that not exists;

                _state.fields[key] = { value: _state.fields[key].value, errors: value };
            });
            return _state;

        case AuthActions.AUTH_LOGOUT:
            axios.defaults.headers.common['Authorization'] = null;

            localStorage.setItem('token', '');
            localStorage.setItem('JWTClaimModel', '');

            _state.token = '';
            _state.jWTClaimModel = null;

            setTimeout(() => {
                //console.log('auth');
                history = action.payload;
                history.push("/auth");
            }, 1);

            return _state;

        case AuthActions.AUTH_REQUEST_BY_STORAGE:
            _state.isBeforeLoadingByStorage = true;
            return _state;

        case AuthActions.AUTH_SUCCESS_BY_STORAGE:
            _state.isBeforeLoadingByStorage = false;
            _state.token = action.payload.token;
            _state.jWTClaimModel = action.payload.jWTClaimModel;

            axios.defaults.headers.common['Authorization'] = `Bearer ${_state.token}`;

            localStorage.setItem('token', _state.token);
            localStorage.setItem('JWTClaimModel', jwt_decode(_state.token).JWTClaimModel);

            return _state;

        case AuthActions.AUTH_FAILURE_BY_STORAGE:
            _state.isBeforeLoadingByStorage = false;

            axios.defaults.headers.common['Authorization'] = null;

            localStorage.setItem('token', '');
            localStorage.setItem('JWTClaimModel', '');

            _state.token = '';
            _state.jWTClaimModel = null;

            setTimeout(() => {
                //console.log('auth');
                history = action.payload;
                history.push("/auth");
            },1);

            return _state;

        default:
            return _state;
    }
}

export default authReducer;
