import React from 'react';
import Alert from '@material-ui/lab/Alert';

export default function NotFound()  {
    return (
        <div>
            <Alert severity="error">未能找到網頁</Alert>
        </div>
    );
}
