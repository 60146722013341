import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const LogoutIcon = (props) => {
    return <SvgIcon {...props}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700">
            <path fill="none" d="M-1-1h702v702H-1z" />
            <g>
                <circle cx="350.5" cy="348.453" r="348" fill="#ffffff" />
                <path fill="#2196f3" d="M424.668 390.336c-35.285 0-64-28.715-64-64s28.715-64 64-64h42.664v-85.332c0-35.285-28.715-64-64-64H174c-7.02 0-13.59 3.453-17.578 9.234-3.969 5.782-4.863 13.145-2.348 19.692l154.668 405.336c3.137 8.277 11.07 13.738 19.926 13.738h74.664c35.285 0 64-28.715 64-64V390.336h-42.664zm0 0" />
                <path fill="#607d8b" d="M523.164 431.383C515.207 428.074 510 420.289 510 411.668v-64h-85.332c-11.777 0-21.336-9.555-21.336-21.332 0-11.777 9.559-21.332 21.336-21.332H510v-64c0-8.621 5.207-16.406 13.164-19.715a21.33 21.33 0 0123.25 4.633l85.336 85.332c8.34 8.34 8.34 21.824 0 30.164l-85.336 85.336a21.335 21.335 0 01-23.25 4.629zm0 0" />
                <path fill="#64b5f6" d="M310.45 157.844l-128.192-42.73c-28.93-8.895-56.258 12.46-56.258 40.554v384c0 18.242 11.605 34.52 28.887 40.492l128.168 42.73c4.715 1.45 9.047 2.114 13.613 2.114 23.531 0 42.664-19.137 42.664-42.668v-384c0-18.238-11.605-34.516-28.883-40.492zm0 0" />
            </g>
        </svg>
    </SvgIcon>
}

export default LogoutIcon;