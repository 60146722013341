import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import TickIcon from './svgIcons/TickIcon';
import SlashIcon from './svgIcons/SlashIcon';

const useStyles = makeStyles({
    root: {
        "transition": "background-color 0.25s ease",
        "cursor": "pointer",
        "&.Mui-selected": {
            background: "rgb(251 254 106 / 8%)",
        },
    },
});

export default function AdEntryItem({ adEntry }) {
    //console.log(adEntry);
    const { Id, DisplayName, Login, LastName, HasSignature } = adEntry;

    const history = useHistory();
    function actionButtonClicked(tag, adEntry) {
        history.push(`/save_photo/${adEntry.Login}`);
    }

    const classes = useStyles();

    return (
        <TableRow className={classes.root} hover onClick={() => { actionButtonClicked("Edit", adEntry); }}>
            <TableCell>{DisplayName}</TableCell>
            <TableCell>{Login}</TableCell>
            <TableCell>{LastName}</TableCell>
            <TableCell align="center">{HasSignature ? <TickIcon /> : <SlashIcon />}</TableCell>
            <TableCell align="center"><Button variant="contained" color="default" onClick={() => { actionButtonClicked("Edit", adEntry); }}>修改</Button></TableCell>
        </TableRow>
    );
}