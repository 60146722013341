import React from 'react';
import { Grid } from '@material-ui/core';

export default function CenterElement({ children }) { // default props has children component
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item>
                {children}
            </Grid>
        </Grid> 

    );
}
