import axios from 'axios';
import _, { cloneDeep } from 'lodash';

// action types
export const FetchAdEntityActions = Object.freeze({
    RESET_ADENTITIES: Symbol("RESET_ADENTITIES"),

    FILTER_UPDATE: Symbol("FILTER_UPDATE"),

    FETCH_ADENTITIES_REQUEST: Symbol("FETCH_ADENTITIES_REQUEST"),
    FETCH_ADENTITIES_SUCCESS: Symbol("FETCH_ADENTITIES_SUCCESS"),
    FETCH_ADENTITIES_FAILURE: Symbol("FETCH_ADENTITIES_FAILURE"),
});

// action creator (returns action obj) 對應每一個 action，有可能食 param 砌 payload
// reducer 接咗 (見下面 reducer)，然後 switch 該個 action 嘅 type，再應用該個 action 有可能提供嘅 payload 改變 state
export const resetAdEntities = () => {
    return {
        type: FetchAdEntityActions.RESET_ADENTITIES
    }
}

export const filterUpdate = (property, value) => {
    return {
        type: FetchAdEntityActions.FILTER_UPDATE,
        payload: {
            property: property,
            value: value,
        }
    }
}

const fetchAdEntitiesRequest = () => {
    return {
        type: FetchAdEntityActions.FETCH_ADENTITIES_REQUEST
    }
}

const fetchAdEntitiesSuccess = (result, filter) => {
    return {
        type: FetchAdEntityActions.FETCH_ADENTITIES_SUCCESS,
        payload: {
            result: result,
            filter: filter
        }
    }
}

const fetchAdEntitiesFailure = (error) => {
    return {
        type: FetchAdEntityActions.FETCH_ADENTITIES_FAILURE,
        payload: error
    }
}

// async action creator (action creator returns action)
// thunkMiddleware allow action creator returns function (instead of an action obj)
export const fetchAdEntities = (filter) => {
    const apiEndpoint = `${window.location.origin}/api/AdEntity/GetEntities`;

    return (dispatch) => {
        dispatch(fetchAdEntitiesRequest());
        axios.post(apiEndpoint, filter)
            .then(response => {
                dispatch(fetchAdEntitiesSuccess(response.data, filter));
            })
            .catch(error => {
                dispatch(fetchAdEntitiesFailure(error.message));
            })
    }
}

// whole state
const initialState = {
    loading: false,
    entities: [],
    error: '',
    filter: {
        row: 1,
        page: 1,
        qtyPerPage: 20,
        filter: "[]",
        sort: "[]"
    },
    totalRecords: 0,
    matchedRecords: 0,
    allRecords: 0
}

// reducer
const adEntityReducer = (state = initialState, action) => {
    let _state = cloneDeep(state);

    switch (action.type) {
        case FetchAdEntityActions.FILTER_UPDATE:
            const { property, value } = action.payload;
            let filters = JSON.parse(state.filter.filter);
            const index = _.findIndex(filters, function (f) { return f.property == property });

            _state.filter.page = 1;

            if (!value) { // remove
                _.pullAt(filters, [index]);
                _state.filter.filter = JSON.stringify(filters);
                return _state;
            }

            if (filters.length == 0) { // empty
                //console.log("empty ==> append");
                _state.filter.filter = JSON.stringify([
                    {
                        property: property,
                        value: value,
                    }
                ]);
                return _state;
            }

            if (index < 0) { // not exists, append
                //console.log("not exists ==> append");
                _state.filter.filter = JSON.stringify([
                    ...filters,
                    {
                        property: property,
                        value: value,
                    }
                ]);
                return _state;
            }

            // replace
            //console.log("exists ==> replace");
            filters[index] = {
                property: property,
                value: value,
            };
            _state.filter.filter = JSON.stringify(filters);
            return _state;

        case FetchAdEntityActions.RESET_ADENTITIES:
            return initialState
        case FetchAdEntityActions.FETCH_ADENTITIES_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case FetchAdEntityActions.FETCH_ADENTITIES_SUCCESS:
            const { result, filter } = action.payload;
            if (!result.success) {
                return {
                    ...state,
                    loading: false,
                    entities: [],
                    error: result.message
                }
            }
            return { 
                ...state,
                loading: false,
                entities: result.entities,
                error: '',
                filter: {
                    filter: result.filter,
                    sort: result.sort,
                    qtyPerPage: result.qtyPerPage,
                    page: result.page,
                    row: result.row,
                },
                allRecords: result.allRecords,
                matchedRecords: result.matchedRecords,
                totalRecords: result.totalRecords,
            }
        case FetchAdEntityActions.FETCH_ADENTITIES_FAILURE:
            return {
                ...state,
                loading: false,
                entities: [],
                error: action.payload
            }
        default:
            return state;
    }
}

export default adEntityReducer;