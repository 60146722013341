import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import _ from 'lodash';

import { fieldChanged, asyncSubmit, fetchNotification, asyncPhotoAdded } from '../redux/notificationReducer';
import {
    Grid,
    TextField,
    Button,
    Paper,
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    CircularProgress,
    Breadcrumbs,
    Typography,
    Link,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormHelperText,
    RadioGroup,
    Radio,
    Card,
    CardMedia
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

import DateFnsUtils from '@date-io/date-fns';
import zhTW from 'date-fns/locale/zh-TW';

import { getTitle } from '../helpers/NotificationHelpers';

import { FiSave } from 'react-icons/fi';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { FilePond, registerPlugin } from 'react-filepond';

import NotificationTopicAutocomplete from './NotificationTopicAutocomplete';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function NotificationForm(notification = null) {
    const history = useHistory();

    // 由 global state 取得 module 嘅 payload
    const { isLoading, filter, workingEntity, workingErrors } = useSelector(state => {
        return state.notifications;
    });

    const dispatch = useDispatch();

    const { id } = useParams();

    // funcComp 應用自己嘅 state
    const [isInit, setIsInit] = useState(false);

    // funcComp 中，相似於 comp 嘅 componentDidMount 和 componentDidUpdate:
    useEffect(() => {
        if (isInit) return;

        setIsInit(true);

        if (parseInt(id)) dispatch(fetchNotification(id));
    });

    const onSubmit = async (e) => {
        await dispatch(asyncSubmit(workingEntity, filter));

        if (workingErrors.length == 0) {
            history.push("/notification");
            return;
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        dispatch(fieldChanged(e.target.id, e.target.value));
    }

    const onAddFile = async (err, fileItem) => {
        if (err) return;

        dispatch(asyncPhotoAdded(fileItem));
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                <Link color="inherit" onClick={() => { history.push("/"); }}>首頁</Link>
                <Link color="inherit" onClick={() => { history.push("/notifications"); }}>通知記錄</Link>
                <Typography color="textPrimary" variant="h6">{getTitle(workingEntity)}</Typography>
            </Breadcrumbs>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={zhTW} >
                <form onSubmit={(e) => { return false; }} onChange={onChange} noValidate>
                <Paper className="p-3 pb-5">
                    <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6}>
                                <NotificationTopicAutocomplete
                                    value={workingEntity.Topic}
                                    error={workingErrors.Topic}
                                />
                        </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                            <KeyboardDatePicker
                                    //margin="normal"
                                    fullWidth
                                    format="yyyy-MM-dd"
                                    label="發佈日期"
                                    value={workingEntity.DateSchedule}
                                    error={!_.isEmpty(workingErrors.DateSchedule)}
                                    helperText={_.isEmpty(workingErrors.DateSchedule) ? "" : workingErrors.DateSchedule}
                                    onChange={(v) => { dispatch(fieldChanged("DateSchedule", v)); }}
                                />
                            </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                                <KeyboardTimePicker
                                    fullWidth
                                    format="hh:mm a"
                                    label="發佈時間"
                                    value={workingEntity.DateSchedule}
                                    error={!_.isEmpty(workingErrors.DateSchedule)}
                                    helperText={_.isEmpty(workingErrors.DateSchedule) ? "" : workingErrors.DateSchedule}
                                    onChange={(v) => { dispatch(fieldChanged("DateSchedule", v)); }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>

                            <FormControl component="fieldset">
                                <FormLabel component="legend">類型</FormLabel>
                                    <RadioGroup row name="MessageType" value={workingEntity.MessageType} onChange={(e) => { dispatch(fieldChanged("MessageType", e.target.value)); }}>
                                    <FormControlLabel
                                            value="新盤"
                                        control={<Radio color="primary" />}
                                        label="新盤"
                                    />
                                    <FormControlLabel
                                            value="客戶查詢"
                                        control={<Radio color="primary" />}
                                        label="客戶查詢"
                                    />
                                    <FormControlLabel
                                        value="Soldout"
                                        control={<Radio color="primary" />}
                                        label="Soldout"
                                    />
                                        <FormControlLabel value="通知" control={<Radio color="primary" />} label="通知" />
                                        <FormControlLabel value="其他" control={<Radio color="primary" />} label="其他" />
                                </RadioGroup>
                            </FormControl>
                                </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <TextField
                                fullWidth
                                label="標題"
                                id="Title"
                                value={_.isEmpty(workingEntity.Title) ? "" : workingEntity.Title}
                                error={!_.isEmpty(workingErrors.Title)}
                                helperText={_.isEmpty(workingErrors.Title) ? "" : workingErrors.Title}
                            />
                        </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="內容"
                                    id="Body"
                                    value={_.isEmpty(workingEntity.Body) ? "" : workingEntity.Body}
                                    error={!_.isEmpty(workingErrors.Body)}
                                    helperText={_.isEmpty(workingErrors.Body) ? "" : workingErrors.Body}
                                />
                            </Grid>

                            {workingEntity.ImageUrl && 
                        <Grid item xs={12} sm={6}>
                            <Card>
                                <CardMedia
                                        component="img"
                                        height="200"
                                        image={workingEntity.ImageUrl}
                                />
                            </Card>
                        </Grid>
}                       
                            <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                    {workingEntity.ImageUrl &&
                                        <Grid item xs={12}>
                                            <Button fullWidth variant="contained" disableElevation onClick={() => { dispatch(fieldChanged("ImageUrl", "")); dispatch(fieldChanged("ImageName", "")); }}>刪除圖片</Button>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <FormControl fullWidth error={!_.isEmpty(workingErrors.ImageUrl)}>
                                            <FilePond
                                                labelIdle="上傳圖片"
                                                imagePreviewHeight={200}
                                                onaddfile={onAddFile}
                                                allowMultiple={false}
                                                maxFiles={1}
                                            >
                                            </FilePond>
                                            {!_.isEmpty(workingErrors.ImageUrl) &&
                                                <FormHelperText>{workingErrors.ImageUrl}</FormHelperText>
                                            }
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">資料類別</FormLabel>
                                    <RadioGroup row name="MessageInstance" value={workingEntity.MessageInstance} onChange={(e) => { dispatch(fieldChanged("MessageInstance", e.target.value)); }}>
                                        <FormControlLabel value="文字" control={<Radio color="primary" />} label="文字" />
                                        <FormControlLabel value="網址" control={<Radio color="primary" />} label="網址" />
                                        <FormControlLabel value="樓盤" control={<Radio color="primary" />} label="樓盤" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            { (workingEntity.MessageInstance == "文字" || workingEntity.MessageInstance == "樓盤") &&
                            <Grid item xs={12} sm={12} md={12}>
                                <FormControl component="fieldset" fullWidth error={!_.isEmpty(workingErrors.Html)}>
                                    <FormLabel component="legend">文字</FormLabel>
                                    <CKEditor
                                        editor={BalloonEditor}
                                        data={_.isEmpty(workingEntity.Html) ? "" : workingEntity.Html}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            dispatch(fieldChanged("Html", data));
                                        }}
                                    />
                                    {!_.isEmpty(workingErrors.Html) && <FormHelperText>{workingErrors.Html}</FormHelperText>}
                                </FormControl>
                            </Grid>
                            }
                            { (workingEntity.MessageInstance == "網址") &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="網址"
                                    id="Link"
                                    value={_.isEmpty(workingEntity.Link) ? "" : workingEntity.Link}
                                    error={!_.isEmpty(workingErrors.Link)}
                                    helperText={_.isEmpty(workingErrors.Link) ? "" : workingErrors.Link}
                                />
                            </Grid>
                            }
                            { (workingEntity.MessageInstance == "樓盤") &&
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    fullWidth
                                    label="樓盤"
                                    id="RefNos"
                                    value={_.isEmpty(workingEntity.RefNos) ? "" : workingEntity.RefNos}
                                    error={!_.isEmpty(workingErrors.RefNos)}
                                    helperText={_.isEmpty(workingErrors.RefNos) ? "" : workingErrors.RefNos}
                                />
                            </Grid>
                            }
                    </Grid>
                </Paper>
                <Grid container justify="center" alignContent="flex-end" spacing={1}>
                        <Grid item style={{ padding: 20 }} >
                            <Button color="primary" variant="contained" disableElevation onClick={onSubmit}>
                            <FiSave className="mr-2" />
                            <span>保　存</span>
                        </Button>
                    </Grid>
                </Grid>
            </form>
            </MuiPickersUtilsProvider>

            <Dialog open={isLoading}>
                <DialogContent>
                    <Box display="flex" >
                        <Box m="auto">
                            <CircularProgress />
                        </Box>
                    </Box>
                    <DialogContentText m="auto">請稍候…</DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}