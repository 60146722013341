import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import axios from 'axios';
import _ from 'lodash';
import { Grid, FormControl, FormHelperText, Input, InputLabel, Button, Dialog, DialogContent, DialogContentText, Box } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { Alert } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FaCheckDouble, FaCloudUploadAlt } from 'react-icons/fa'

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import { FilePond, registerPlugin } from 'react-filepond';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

function SavePhoto() {
  const history = useHistory();
  const params = useParams();

  // funcComp 應用自己嘅 state
  const [action, setAction] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadOk, setIsUploadOk] = useState(false);
  const [login, setLogin] = useState({ value: params.login ? params.login : "", errors: [] });
  const [photo, setPhoto] = useState({ value: '', errors: [] });
  const [files, setFiles] = useState([]);

  const refForm = useRef(null);


  // funcComp 中，相似於 comp 嘅 componentDidMount 和 componentDidUpdate:
  useEffect(() => {
    if (isInit) return;

    setIsInit(true);
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const apiEndpoint = `${window.location.origin}/api/AdEntity/${action}`;

    let data = new FormData();
    //console.log(files);
    //console.log(login);

    data.append('login', login.value);
    if (!_.isEmpty(files)) data.append('Photo', files[0]);

    setIsUploadOk(false);
    await axios.post(apiEndpoint, data, {
      timeout: 30000,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((result) => {  // state 係 resolve
        /*console.log('result', result);*/

        setLogin({
          ...login,
          errors: [],
        });
        setPhoto({
          ...photo,
          errors: [],
        });
        setIsUploadOk(true);
      })
      .catch((result) => { // failed
        //console.log('result', result);

        if (result.response && result.response.data && result.response.data.errors) {
          const errors = result.response.data.errors;
          setLogin({
            ...login,
            errors: errors.Login ? errors.Login : errors.login ? errors.login : [],
          });
          setPhoto({
            ...photo,
            errors: errors.Photo ? errors.Photo : errors.photo ? errors.photo : [],
          });

        } else {
          setLogin({
            ...login,
            errors: [],
          });
          setPhoto({
            ...photo,
            errors: [(result.response & result.response.message) ? result.response.message : "未能上傳"],
          });
        }

        setIsUploadOk(false);
      });

    setIsLoading(false);
  };

  const onUpdateFiles = (fileitems) => {
    //console.log('onUpdateFiles');
    setFiles(fileitems.map(fileitem => fileitem.file));
  };

  return (
    <React.Fragment>
      <Breadcrumbs aria-label="breadcrumb" className="mb-3">
        <Link color="inherit" onClick={() => { history.push("/"); }}>首頁</Link>
        <Link color="inherit" onClick={() => { history.push("/users"); }}>登記用戶</Link>
        <Typography color="textPrimary" variant="h6">更改頭像</Typography>
      </Breadcrumbs>

      {isUploadOk && <Alert className="mb-3" severity="success">{action == "TestPhoto" ? "相片通過身份識別" : "已上傳"}</Alert>}

      <form onSubmit={onSubmit} ref={refForm}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!_.isEmpty(photo.errors)}>
              <FilePond
                labelIdle="上傳照片 (請調較解像度約為 300 x 300)"
                imagePreviewHeight={200}
                onupdatefiles={onUpdateFiles}
                allowMultiple={false}
              >
              </FilePond>
              {!_.isEmpty(photo.errors) &&
                <FormHelperText>{photo.errors.map((e, i) => <span key={i}>{e}</span>)}</FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!_.isEmpty(login.errors)}>
              <InputLabel htmlFor="my-input">電郵</InputLabel>
              <Input id="login" aria-describedby="my-helper-text" autoFocus value={login.value} onSubmit={onSubmit} onChange={(e) => {
                const match = e.target.value.match(/(.+)@c.*/);
                let v = e.target.value;

                if (match) v = `${match[1]}@century21-goodwin.com`;

                setLogin({
                  ...login,
                  value: v
                });
              }} />
              {!_.isEmpty(login.errors) &&
                <FormHelperText>{login.errors.map((e, i) => <span key={i}>{e}</span>)}</FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container className="justify-content-center">
              <Button className="mr-2" type="submit" onClick={() => { setAction("TestPhoto"); }} color="primary" variant="contained" disableElevation>
                <FaCheckDouble className="mr-2" />
                <span>測試用戶已登記的頭像</span>
              </Button>
              <Button type="submit" color="primary" onClick={() => { setAction("SavePhoto"); }} variant="contained" disableElevation>
                <FaCloudUploadAlt className="mr-2" />
                <span>更改頭像</span>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
        </Grid>
      </form>

      <Dialog open={isLoading}>
        <DialogContent>
          <Box display="flex" >
            <Box m="auto">
              <CircularProgress />
            </Box>
          </Box>
          <DialogContentText m="auto">請稍候…</DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SavePhoto;
